#order-page .rendercontrols {
    padding-top: 15px;
}

#order-page .rendercontrols .MuiGrid-root.MuiGrid-container .MuiGrid-item {
    padding-top: 0px;
}

#order-page .rendercontrols .MuiGrid-root.MuiGrid-container.chk-group-excludes {
    margin-top: 0px;
}

#order-page .rendercontrols input.MuiInputBase-input {
    padding-top: 16px;
    font-size: 14px;
}

#order-page .rendercontrols div.MuiInputBase-input {
    padding-top: 16px;
    font-size: 16px;
}

#order-page .rendercontrols .MuiAutocomplete-root input.MuiInputBase-input {
    padding-top: 3px;
    font-size: 16px;
}

/* input label style */
#order-page .MuiFormLabel-root {
    margin-top: -2px;
    font-size: 14px;
}

#order-page .rendercontrols div.MuiGrid-item .MuiAutocomplete-root[name=tags_eq] .MuiFilledInput-root {
    padding-top: 15px;
    padding-bottom: 0px;
}


#order-page .RaDatagrid-tableWrapper .column-date {
    min-width: 120px;
}

#order-page .RaDatagrid-tableWrapper .column-event-venue-order_total {
    /* width: 380px; */
    width: 18%;
}

#order-page .RaDatagrid-tableWrapper .column-order_notes {
    width: 16%;
    max-width: 320px;
    white-space: pre;
    overflow: hidden;
    position: relative;
}
#order-page .RaDatagrid-tableWrapper .column-order_notes .highlight-by {
    position: absolute;
    transform: rotate(90deg);
    transform-origin: left top 0;
    left: 100%;
    text-align: center;  
    top: -1px;
    color: white;
}
/* highlight selected row */
#order-page .RaDatagrid-tableWrapper tr.RaDatagrid-row:has(+ .RaDatagrid-expandedPanel)  {
    /* background-color: #68b96a29; */
    background-color: #579cfd29;
}
/* fill color for expanded content */
#order-page .RaDatagrid-tableWrapper tr.RaDatagrid-expandedPanel  {
    background-color: #9b9b891f;
}

#order-page .RaDatagrid-tableWrapper .column-in_hand_date {
    width: 160px;
}

#order-page .RaDatagrid-tableWrapper .column-internal_status-delivery_method {
    max-width: 100px;
    width: 11%;
}

#order-page .RaDatagrid-tableWrapper .column-primary_order-primary_account {
    /* width: 170px; */
    width: 12%;
    /* overflow-wrap: anywhere; */
}

/* MuiButtonBase-root (Status button) */
#order-page .RaDatagrid-tableWrapper .RaDatagrid-rowCell .MuiButton-root {
    width: -webkit-fill-available;
    font-size: 12px;
    font-weight: bold;
    padding-top: 1px;
    padding-bottom: 1px;
    color: white;
}

/* .css-wjsjww-MuiChip-label Chip label */
#order-page .RaDatagrid-tableWrapper .statusChip, #order-page .RaDatagrid-tableWrapper .MuiChip-root {
    /*.RaDatagrid-rowCell .css-wjsjww-MuiChip-label {*/
    margin: 1px;
    font-size: 9px;
    height: 20px;
    color: white;
    font-weight: bold;
}

#order-page .RaDatagrid-tableWrapper .RaDatagrid-rowCell .custom-Tag {
    padding: 2px;
    border-radius: 4px;
    color: white;
}

/* MuiTableCell-paddingNone */
#order-page .RaDatagrid-tableWrapper .MuiTableCell-paddingNone {
    display: none;
}

/* style datepicker in detail */
#order-page .RaDatagrid-tableWrapper .datepicker-padding input {
    padding-top: 14px;
}

/* style mselect  */
#order-page .RaDatagrid-tableWrapper .mselect-padding [role=combobox] {
    padding-top: 14px;
}

#order-page .MuiTableCell-root .MuiTypography-root {
    font-size: 13px;
}

#order-page .Form-Order .control-label {
    font-size: 13px;
    font-weight: bold;
}

.RaDatagrid-root .MuiTableCell-root.MuiTableCell-head, .MuiToolbar-root.RaToolbar-desktopToolbar, .RaDatagrid-headerCell {
    font-weight: bold;
}

#order-page .MuiTableCell-root,
ul#mautocomplete-listbox .MuiMenuItem-root, .mselect-small .MuiMenuItem-root,
#order-page .Form-Order .MuiFormLabel-root,
#order-page .Form-Order .MuiInputBase-root,
#order-page .Form-Order .MuiTypography-root, .ag-root .ag-cell {
    font-size: 13px !important;
}

#order-page .MuiTableCell-root .smallInput input {
    font-size: 12px;
    padding: 5px;
}

#order-page .MuiTableCell-root .smallInput button {
    padding: 2px 0px 8px 0px;
}

/* style for primary button in dark theme */
#order-page .dark-primary-button {
    color: white;
    background-color: rgb(22, 106, 197);
}

#order-page .dark-primary-button:hover {
    color: white;
    background-color: rgb(3, 71, 143);
}

#order-page .Form-Order .order-notes .ag-cell {
    align-items: center;
    display: grid;
}

#order-page .Form-Order .order-notes .ag-floating-top-container {
    width: 100%;
}

#order-page .Form-Order .order-notes .ag-floating-top-container .ag-cell {
    padding: 0px;
}

#order-page .Form-Order .order-notes .MuiSvgIcon-root {
    font-size: 1.0rem;
}

/* alert info*/
#order-page .Form-Order .alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

#order-page .Form-Order .alert-info {
    margin: 10px 0px;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

/* for dialog */
div.order-dialog .MuiGrid-root.MuiGrid-item, div.order-dialog .MuiInputBase-root,
div.order-dialog .MuiTypography-root,
div.order-dialog .MuiDataGrid-columnHeader {
    font-size: 13px;
}

div.order-dialog .MuiDialogTitle-root {
    font-size: 20px;
}

div.order-dialog .MuiChip-root {
    max-height: 20px;
}